<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li *ngIf="detailUser?.length<=0"><a routerLink="/">Home</a></li>
                <li *ngIf="detailUser"><a routerLink="/elenco-corsi/i-miei-corsi">Home</a></li>
                <li>Contatti</li>
            </ul>
            <h2>Contattaci</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-image">
    <img  src="assets/img/university-contatti.jpg"  style="max-height:550px; width:100%; object-fit: cover;  object-position: bottom bottom;" alt="image">
</div>

<div class="contact-area ptb-100">
    <div class="container">
        <div class="row mb-5">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Chi siamo</span>
                    <h2>FormEdil Caserta</h2>
                    <p>
                        è un Organismo Bilaterale Paritetico del settore delle Costruzioni, come stabilito dall’art. 51 del D.Lgs. 81/08.
                    </p>
                    <p>
                        Non ha scopo di lucro ed ha il compito di perseguire i fini istituzionali previsti dai Contratti Collettivi stipulati tra le parti sociali A.N.C.E. (Associazione Nazionale Costruttori Edili) e FENEAL-UIL, FILCA-CISL, FILLEA-CGIL), offrendo servizi alle imprese a ai lavoratori della provincia di Caserta.
                    </p>
                    <p>
                        Svolge le sue attività nell’ambito della Formazione e della Sicurezza strettamente integrate tra loro.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">dalla formazione alla professione</span>
                    <h2>Le nostre attività e i servizi</h2>
                   <p>
                        Nell’ambito della Formazione, organizza ed eroga attività di formazione specifica ed integrata per la Salute e Sicurezza sul Lavoro, destinata a:
                   </p>
                    <p>
                        Nell’ambito della Sicurezza, offre a tutte le imprese edili che operano nella Provincia di Caserta un servizio di consulenza GRATUITO il cui scopo è di individuare situazioni di criticità in materia di salute e sicurezza sul lavoro e fornire suggerimenti utili al miglioramento delle condizioni di salute e sicurezza di quanti operano nel settore.
                    </p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Informazioni</span>
                    <h2>Mettiti in contatto</h2>
                    <p>Ci piace ascoltare i lettori. Apprezziamo davvero che tu abbia dedicato del tempo per metterci in contatto.</p>
                    <ul>
                        <li>
                            <div class="icon">
                                <i class='bx bx-map'></i>
                            </div>
                            <h3>Dove siamo</h3>
                            <p>Via Unità Italiana, 19<br>81100 Caserta (CE)</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <h3>Contatti</h3>
                            <p>Telefono: <a href="tel:+393889364181">(+39) 388-9364181</a></p>
                            <p>e-mail: <a href="mailto:academy@formedilcaserta.it">academy@formedilcaserta.it</a></p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-time-five'></i>
                            </div>
                            <h3>Siamo disponibili</h3>
                            <p>Lunedì - Venerdì<br>09:00 - 18:00</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
                    <h2>Come possiamo aiutarti?</h2>
                    <p>L'indirizzo email non verrà pubblicato. i campi richiesti sono contrassegnati dal simbolo*</p>
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="Nome e Cognome">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Nome e Cognome richiesto.</div>
                                        <div *ngIf="name.errors.minlength">Numero minimo {{ name.errors.minlength.requiredLength }} di caratteri.</div>
                                        <div *ngIf="name.errors.maxlength">Numero massimo di 50 caratteri.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="la tua e-mail">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number" placeholder="cellulare">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">un contatto telefonico è obbligatorio</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="5" class="form-control" placeholder="scrivi il tuo messaggio..."></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">il messaggio è obbligatorio</div>
                                </div>
                            </div>
                            <h2 class="mt-3">Quale corso sei interessato?</h2>
                            <p>seleziona il corso interessato alla quale vuoi avere informazioni*</p>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <select name="sceltaCorso" ngModel id="sceltaCorso" #sceltaCorso="ngModel" style="display: block;
                                    width: 100%;
                                    border: none;
                                    color: var(--blackColor);
                                    background-color: #F3F6F9;
                                    height: 50px;
                                    padding-left: 15px;
                                    border-radius: 5px;
                                    font-size: var(--fontSize);
                                    font-weight: 600">
                                        <option value="{{elencoCorsiRow['corso']['titolo']}}" *ngFor="let elencoCorsiRow of elencoCorsi">{{elencoCorsiRow['corso']['titolo']}}</option>
                                      </select>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 mt-3">
                                <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid">Invia Richiesta<span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
