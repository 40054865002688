<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Privacy Policy</li>
            </ul>
            <h2>Privacy Policy</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="privacy-policy-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="privacy-policy-content">
                    Questa comunicazione Vi viene resa disponibile – anche ai sensi dell’art. 13 del Regolamento Europeo 2016/679 in materia di protezione dei dati personali (“Regolamento” o “GDPR”) e del D.lgs. 30/06/2003 n. 196 (“Codice Privacy”), come modificato e integrato dal D.lgs. 101/2018, e delle successive modifiche ed integrazioni – da FormEdil Caserta srls con sede legale in Nola (Na), via San Massimo 301, in qualità di Titolare del trattamento dei Vostri dati personali.
<br><br>
La presente Informativa ha lo scopo di informare l’utente circa le modalità di trattamento dei dati personali che lo riguardano.
<br><br>
TAB 1 - TIPOLOGIA DI DATI TRATTATI<br>
Il sito web offre contenuti di tipo informativo e, talvolta, interattivo. Durante la navigazione del sito Discentya potrà, quindi, acquisire informazioni sul visitatore, nei seguenti modi:
Dati di navigazione. I sistemi informatici e le procedure software preposte al funzionamento di questo sito web acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita nell’uso dei protocolli di comunicazione di Internet. In questa categoria di dati rientrano: gli indirizzi IP, il tipo di browser utilizzato, il sistema operativo, il nome di dominio e gli indirizzi di siti web dai quali è stato effettuato l’accesso, le informazioni sulle pagine visitate dagli utenti all’interno del sito, l’orario d’accesso, la permanenza sulla singola pagina, l’analisi di percorso interno ed altri parametri relativi al sistema operativo e all’ambiente informatico dell’utente.
Ulteriori categorie di dati<br>
Si tratta di tutti quei dati personali forniti dal visitatore attraverso il sito, ad esempio:
<ul>
<li>compilando un modulo attraverso il quale richiedere informazioni sui servizi offerti e/o una richiesta di contatto;</li>
<li>scrivendo agli indirizzi di posta elettronica indicati nel nostro sito per richiedere informazioni;</li>
<li>compilando un modulo per ricevere la nostra newsletter e comunicazioni di marketing;</li>
</ul>

<br>
TAB 2 – FINALITÀ DEL TRATTAMENTO<br>
I dati forniti sono trattati per le seguenti finalità:
<ul>
    <li>fornire l’informazione e/o il servizio richiesto dall’utente, gestire i contratti perfezionati dall’utente, espletare i relativi adempimenti amministrativi, contabili, fiscali e legali, nonché evadere le richieste inoltrate dall’utente. I trattamenti posti in essere per queste finalità sono necessari per l’adempimento di obblighi contrattuali e non necessitano di uno specifico consenso da parte dell’interessato;</li>
    <li>rilevare l’esperienza d’uso delle nostre piattaforme, dei prodotti e servizi che offriamo e assicurare il corretto funzionamento delle pagine web e dei loro contenuti. I trattamenti posti in essere per queste finalità si basano su un legittimo interesse del Titolare;</li>
    <li>inviare comunicazioni commerciali relative a promozioni e/o offerte, nell’interesse del Titolare; i trattamenti posti in essere per queste finalità vengono effettuati con lo specifico consenso fornito dall’utente.</li>
</ul>


<br>
TAB 3 - CONDIVISIONE E TRASFERIMENTO DEI DATI PERSONALI<br>
I dati raccolti da FormEdil Caserta srls verranno condivisi solo per le finalità di cui sopra; non condivideremo o trasferiremo i Vostri dati personali a terzi diversi da quelli indicati nella presente Informativa sulla privacy.
Nel corso delle nostre attività ed esclusivamente per gli stessi scopi di quelli elencati nella presente Informativa sulla privacy, i Vostri dati personali potranno essere trasferiti alle seguenti categorie di destinatari:
<ul>
    <li>il personale dell’azienda;</li>
    <li>fornitori di servizi, (ad es. fornitori di sistemi IT, fornitori di servizi cloud, fornitori di database e consulenti);</li>
    <li>Pubbliche Amministrazioni per finalità di legge;</li>
    <li>ogni soggetto pubblico e/o privato a cui si renda necessaria le comunicazioni dei Vostri dati personali in relazione alle finalità sopra indicate.</li>
</ul>
L’elenco aggiornato dei Responsabili del trattamento è disponibile presso la sede legale del Titolare e sarà fornito previa richiesta scritta.
FormEdil Caserta srls potrebbe dover trasferire i Vostri dati personali a paesi situati al di fuori dell’Unione Europea/Spazio Comune Europeo (EEA), verso i cosiddetti “paesi terzi”. Tali trasferimenti verso paesi terzi possono comprendere tutte le attività di elaborazione di cui sopra.
La presente Informativa sulla privacy è applicabile anche nel caso di trasferimento di dati a paesi terzi in cui il livello di protezione dei dati sia diverso rispetto a quello dell’Unione Europea: ogni trasferimento di dati personali a terzi sarà effettuato solo dopo averVi informato e, ove richiesto, dopo aver ricevuto il Vostro consenso. Ogni trasferimento di dati verso paesi diversi da quelli per cui la Commissione Europea abbia preso una decisione di adeguatezza avviene sulla base di accordi che utilizzano clausole contrattuali standard adottate dalla Commissione Europea o altre garanzie adeguate nel rispetto delle leggi vigenti.
<br><br>
TAB 4 - PROTEZIONE DEI DATI PERSONALI<br>
FormEdil Caserta srls ha implementato misure tecniche e organizzative adeguate a fornire un livello adeguato di sicurezza e riservatezza ai dati personali.
Queste misure prendono in considerazione:
<ul>
    <li>lo stato dell’arte della tecnologia;</li>
    <li>i costi della sua implementazione;</li>
    <li>la natura dei dati;</li>
    <li>il rischio del trattamento.</li>
</ul>
Lo scopo è proteggerli da distruzione o alterazione accidentali o illecite, perdita accidentale, divulgazione o accessi non autorizzati e da altre forme di elaborazione illecite.
Inoltre, quando gestisce i Vostri dati personali, FormEdil Caserta srls: raccoglie e tratta i dati personali che sono adeguati, pertinenti e non eccessivi, come richiesto per soddisfare le finalità di cui sopra e assicura che tali dati personali rimangano aggiornati e accurati.
<br><br>

TAB 5 – TEMPI E CONSERVAZIONE DEI DATI<br>
Fatto salvo il Vostro diritto a opporVi al trattamento dei dati personali e/o a richiederne la cancellazione, FormEdil Caserta srls conserverà i Vostri dati personali solo per il tempo necessario a conseguire lo scopo per cui sono stati raccolti e ricevuti, o per soddisfare i requisiti legali o regolamentari.
Quando questo periodo scade, i Vostri dati personali verranno rimossi dai sistemi attivi di FormEdil Caserta srls.

<br><br>
TAB 6 – I VOSTRI DIRITTI DI LEGGE<br>
I diritti connessi ai dati personali che FormEdil Caserta srls tratta sono:
<ul>
    <li>Diritto alla rettifica. Voi potete ottenere la rettifica dei dati personali che Vi riguardano o da Voi comunicateci. FormEdil Caserta srls compie sforzi ragionevoli per fare sì che i dati personali in suo possesso siano precisi, completi, aggiornati e pertinenti, sulla base delle informazioni più recenti a disposizione;</li>
    <li>Diritto alla limitazione. Voi potete ottenere una limitazione al trattamento dei Vostri dati personali qualora:</li>
    <li>contestiate la precisione dei Vostri dati personali nel periodo in cui FormEdil Caserta srls deve verificarne l’accuratezza;</li>
    <li>il trattamento sia illecito e richiedete una limitazione del trattamento o la cancellazione dei Vostri dati personali;</li>
    <li>non sussista più, da parte di FormEdil Caserta srls, la necessità di mantenere i Vostri dati personali ma Voi ne abbiate bisogno per accertare, esercitare o difendere i Vostri diritti in sede giudiziaria oppure</li>
    <li>vi opponiate al trattamento mentre FormEdil Caserta srls verifica se le sue motivazioni legittime prevalgano sulle Vostre.</li>
    <li>Diritto all’accesso. Voi potete chiedere a FormEdil Caserta srls informazioni sui dati personali conservati che Vi riguardano, incluse le informazioni su quali categorie di dati personali FormEdil Caserta srls possiede o controlla, a quale scopo vengano usati, dove sono stati raccolti (se non direttamente da Voi), e a chi siano stati eventualmente comunicati;</li>
    <li>Diritto alla portabilità. In seguito alla Vostra richiesta, FormEdil Caserta srls trasferirà i Vostri dati personali a un altro Titolare del trattamento, se tecnicamente possibile, a condizione che il trattamento sia basato sul Vostro consenso o sia necessario per l’esecuzione di un contratto.</li>
    <li>Diritto alla cancellazione. Voi potrete ottenere da FormEdil Caserta srls la cancellazione dei Vostri dati personali qualora:</li>
    <li>i dati personali non siano più necessari in relazione agli scopi per cui sono stati raccolti o altrimenti trattati;</li>
    <li>voi abbiate diritto a opporVi a un ulteriore trattamento dei Vostri dati personali ed esercitate questo diritto alla opposizione;</li>
    <li>i dati personali siano stati trattati in modo illecito.</li>
</ul>
A meno che il trattamento sia necessario in virtù di obblighi legali, di legge o al fine di costituire, esercitare o difendere un diritto in sede giudiziaria.
<ul>
    <li>Diritto alla opposizione. Voi potete opporVi in qualsiasi momento al trattamento dei Vostri dati personali, alla condizione che il trattamento non sia basato sul Vostro consenso ma sui legittimi interessi di FormEdil Caserta srls o di terzi. In tali ipotesi FormEdil Caserta srls non tratterrà più i Vostri dati personali a meno che sia possibile dimostrarVi i motivi cogenti e legittimi, un interesse prevalente al trattamento o all’accertamento, oppure l’esercizio o la difesa di un diritto in sede giudiziaria. Qualora Voi Vi opponiate al trattamento, specificate cortesemente se intendete cancellare i Vostri dati personali o limitarne il trattamento.</li>
    <li>Diritto di presentare un reclamo. In caso di supposta violazione della legge vigente in materia di privacy, Voi potrete presentare un reclamo presso le autorità competenti del Vostro paese o del luogo ove si sarebbe consumata la presunta violazione.</li>
</ul>

<br><br>
TAB 7 - MODIFICHE DELLA PRESENTE INFORMATIVA SULLA PRIVACY<br>
Eventuali modifiche o integrazioni future al trattamento dei dati personali come descritto nella presente Informativa sulla privacy verranno notificate tramite i consueti canali di comunicazione utilizzati da FormEdil Caserta srls (ad esempio via e-mail o tramite il sito).

<br><br>
TAB 8 - TITOLARE DEL TRATTAMENTO E REFERENTE PER LA PROTEZIONE DEI DATI<br>
Per esercitare i diritti di cui agli artt. 15 e segg. del GDPR, potrete rivolgerVi al Titolare del trattamento, FormEdil Caserta srls con sede legale in Nola (Na), via San Massimo 301.
Il “titolare” del loro trattamento è:
FormEdil Caserta srls
via San Massimo 301 – 80035 - Nola (Na)
Tel.: +39 370-1587002
formazione@ssmlpinerolo.academy
            </div>

        </div>
    </div>
</div>
